$(function () {

    var utensil;
    var geometry;
    var diameter;
    var cr;
    var l;
    var l1;

    // bootstrap filtri di ricerca
    var r_utensil;
    var r_geometry;
    var r_diametro;
    var r_cr;
    var r_l;
    var r_l1;

    var selettore_geometries;
    var selettore_diametro;
    var selettore_cr;
    var selettore_l;
    var selettore_l1;
    var bottone_ricerca;

    var cr_filter;

    init();

    $(window).on('popstate', function () {
        init();
    });

    function init() {
        $('#loading').hide();
        $(".rotella").hide();
        $(".rotella1").hide();
        $(".rotella2").hide();
        $(".rotella3").css('visibility', 'hidden');
        $(".rotella4").css('visibility', 'hidden');
        $('#products').hide();
        $('#product').hide();

        $('#alert').hide();

        $('.iconcina_utensil').hide();
        $('.iconcina_geometry').hide();
        $('.iconcina_cutter').hide();
        $('.iconcina_material').hide();
        $('.iconcina_sector').hide();

        utensil = getParameterByName('utensil');
        geometry = getParameterByName('geometry');
        diameter = getParameterByName('diameter');
        cr = getParameterByName('cr');
        l = getParameterByName('l');
        l1 = getParameterByName('l1');
        productId = getParameterByName('productId');

        // bootstrap filtri di ricerca
        r_utensil = $('input:radio[name=utensil-dimensions]');
        r_geometry = $('option[name=geometry]');
        r_diametro = $('option[name=diametro]');
        r_cr = $('option[name=cr]');
        r_l = $('option[name=l]');
        r_l1 = $('option[name=l1]');

        selettore_geometries = $('.selettore_geometries-dimensions');
        selettore_diametro = $('.selettore_diametro');
        selettore_cr = $('.selettore_cr');
        selettore_l = $('.selettore_l');
        selettore_l1 = $('.selettore_l1');
        bottone_ricerca = $('button#search');

        cr_filter = $('.cr-filter')
        cr_filter.hide()

        if (r_geometry.is(':selected') === false) {
            r_geometry.filter('[value=' + geometry + ']').prop('selected', true);
        }
        if (r_diametro.is(':selected') === false) {
            r_diametro.filter('[value=' + diameter + ']').prop('selected', true);
        }
        if (r_cr.is(':selected') === false) {
            r_cr.filter('[value=' + cr + ']').prop('selected', true);
        }
        if (r_l.is(':selected') === false) {
            r_l.filter('[value=' + l + ']').prop('selected', true);
        }
        if (r_l1.is(':selected') === false) {
            r_l1.filter('[value=' + l1 + ']').prop('selected', true);
        }

        $('.select-diameter').show();
        $('.select-radius').hide();

        // bootstrap ricerca
        // if (geometry !== null && cr !== null && l !== null && productId === null) {
        //     getProducts(buildSearchString(utensil, geometry, cutter, material, sector, diameter));
        // }
        //

        if (productId !== null) {
            getProduct(productId);
        }
    }

    $(document).bind("ajaxSend", function () {
        $("#loading").show();
        $('#products').hide();
        $('#product').hide();
    }).bind("ajaxComplete", function () {
        $("#loading").hide();
        $(".rotella").hide();
        $(".rotella1").hide();
        $(".rotella2").hide();
        $(".rotella3").css('visibility', 'hidden');
        $(".rotella4").css('visibility', 'hidden');
    });

    if (!$("input:radio[name='utensil']").is(':checked')) {
        selettore_geometries.attr("disabled", "disabled");
        selettore_diametro.attr("disabled", "disabled");
        selettore_cr.attr("disabled", "disabled");
        selettore_l.attr("disabled", "disabled");
        selettore_l1.attr("disabled", "disabled");
        bottone_ricerca.attr("disabled", "disabled");
    }

    $('input:radio[name=utensil-dimensions]').on('change', function () {
        selettore_geometries.attr("disabled", "disabled").val('0');
        selettore_diametro.attr("disabled", "disabled").val('0');
        selettore_cr.attr("disabled", "disabled").val('0');
        selettore_l.attr("disabled", "disabled").val('0');
        selettore_l1.attr("disabled", "disabled").val('0');
        bottone_ricerca.attr("disabled", "disabled");

        var name = 'utensil';
        var utensil = $('input[name=utensil-dimensions]:checked').val();
        if (utensil != 0) {
            selettore_geometries.removeAttr("disabled", "disabled");
            $(".rotella").show();
            $.ajax({
                method: 'GET',
                url: products_search_dimensions_parameters_url + '?from=' + name
                    + '&utensil=' + utensil,
                dataType: 'JSON',
                success: function (data) {
                    replaceOptions(selettore_geometries, data.geometries, false);
                }
            });
        }
    });

    selettore_geometries.on('change', function () {
        selettore_diametro.attr("disabled", "disabled").val('0');
        selettore_cr.attr("disabled", "disabled").val('0');
        selettore_l.attr("disabled", "disabled").val('0');
        selettore_l1.attr("disabled", "disabled").val('0');
        bottone_ricerca.attr("disabled", "disabled");

        var name = 'geometry';
        var utensil = $('input[name=utensil-dimensions]:checked').val();
        var geometry = selettore_geometries.val();
        $(".rotella1").show();
        if (geometry != 0) {
            cr_filter.hide()
            if (geometry == toricGeometryId) {
                cr_filter.show()
            }
            $('.select-diameter').show();
            $('.select-radius').hide();
            if ($.inArray(parseInt(geometry), withRadiusGeometryIds) > -1) {
                $('.select-diameter').hide();
                $('.select-radius').show();
            }
            $.ajax({
                method: 'GET',
                url: products_search_dimensions_parameters_url + '?from=' + name
                    + '&utensil=' + utensil
                    + '&geometry=' + geometry,
                dataType: 'JSON',
                success: function (data) {
                    replaceOptionsFloat(selettore_diametro, data.diameter);
                    selettore_diametro.removeAttr("disabled", "disabled");
                }
            });
        }
    });

    selettore_diametro.on('change', function () {
        selettore_cr.attr("disabled", "disabled").val('0');
        selettore_l.attr("disabled", "disabled").val('0');
        selettore_l1.attr("disabled", "disabled").val('0');
        bottone_ricerca.attr("disabled", "disabled");

        var name = 'diameter';
        var utensil = $('input[name=utensil-dimensions]:checked').val();
        var geometry = selettore_geometries.val();
        var diameter = selettore_diametro.val();
        $(".rotella2").show();
        if (diameter != 0) {
            $.ajax({
                method: 'GET',
                url: products_search_dimensions_parameters_url + '?from=' + name
                    + '&utensil=' + utensil
                    + '&geometry=' + geometry
                    + '&diameter=' + diameter
                    + '&isToric=' + (geometry == toricGeometryId),
                dataType: 'JSON',
                success: function (data) {
                    $('button#search').removeAttr("disabled", "disabled");
                    if (geometry == toricGeometryId) {
                        if (Object.keys(data.cr).length > 1) {
                            replaceOptionsFloat(selettore_cr, data.cr);
                            selettore_cr.removeAttr("disabled", "disabled");
                        }
                    }
                    if (Object.keys(data.lenght).length > 1) {
                        replaceOptionsFloat(selettore_l, data.lenght);
                        selettore_l.removeAttr("disabled", "disabled");
                    }
                    if (Object.keys(data.lenght_1).length > 1) {
                        replaceOptionsFloat(selettore_l1, data.lenght_1);
                        selettore_l1.removeAttr("disabled", "disabled");
                    }
                }
            });
        }
    });

    selettore_cr.on('change', function () {
        $(".rotella2").show();
        multiDimensionsSearch()
    });

    selettore_l.on('change', function () {

        $(".rotella3").show();

        multiDimensionsSearch()

    });

    selettore_l1.on('change', function () {

        $(".rotella4").show();

        multiDimensionsSearch()

    });

    function multiDimensionsSearch() {
        // selettore_l.attr("disabled", "disabled").val('0');
        // selettore_l.attr("disabled", "disabled").val('0');
        // selettore_l1.attr("disabled", "disabled").val('0');
        bottone_ricerca.attr("disabled", "disabled");

        var utensil = $('input[name=utensil-dimensions]:checked').val();
        var geometry = selettore_geometries.val();
        var diameter = selettore_diametro.val();
        var cr = selettore_cr.val();
        var l = selettore_l.val();
        var l1 = selettore_l1.val();
        $.ajax({
            method: 'GET',
            url: products_search_dimensions_parameters_url + '?from=multiDimensions'
                + '&utensil=' + utensil
                + '&geometry=' + geometry
                + '&diameter=' + diameter
                + '&cr=' + cr
                + '&l=' + l
                + '&l1=' + l1,
            dataType: 'JSON',
            success: function (data) {
                $('button#search').removeAttr("disabled", "disabled");
                if (data.cr) {
                    replaceOptionsFloat(selettore_cr, data.cr);
                    if (cr) {
                        selettore_cr.val(cr)
                    }
                    selettore_cr.removeAttr("disabled", "disabled");
                }
                if (data.lenght) {
                    replaceOptionsFloat(selettore_l, data.lenght);
                    if (l) {
                        selettore_l.val(l)
                    }
                    selettore_l.removeAttr("disabled", "disabled");
                }
                if (data.lenght_1) {
                    replaceOptionsFloat(selettore_l1, data.lenght_1);
                    if (l1) {
                        selettore_l1.val(l1)
                    }
                    selettore_l1.removeAttr("disabled", "disabled");
                }
            }
        });
    }


    $('button#search').on('click', function () {
        var utensil = $('input[name=utensil-dimensions]:checked').val();
        var geometry = selettore_geometries.val();
        var diameter = selettore_diametro.val();
        var cr = selettore_cr.val();
        var l = selettore_l.val();
        var l1 = selettore_l1.val();
        $.ajax({
            method: 'GET',
            url: products_search_dimensions_url + '?'
                + '&utensil=' + utensil
                + '&geometry=' + geometry
                + '&diameter=' + diameter
                + '&cr=' + cr
                + '&l=' + l
                + '&l1=' + l1,
            success: function (data) {
                $('#products')
                    .show()
                    .find('tbody').html(data);

                $('#product').hide();
                $('#console_ricerca').show();
                $('.ricerca_avanzata_1').show();

                $('html, body').animate({
                    scrollTop: $("#loading").offset().top
                }, 500);
            }
        });
    });

    function getParameters() {
        utensil = $('input[name=utensil-dimensions]:checked').val();
        geometry = selettore_geometries.val();
        diameter = selettore_diametro.val();
        cr = selettore_cr.val();
        l = selettore_l.val();
        l1 = selettore_l1.val();
    }

    $('.table-hover > tbody > tr').mouseenter(function () {
        $('button.product-open', this).css("color", "#F97B00");
    }).mouseleave(function () {
        $('button.product-open', this).css("color", "#333");
    });

    $(document).on('click', '.show-more-click', function (e) {
        var productId = $(e.target).attr('data-id')
        if ($(e.target).attr('data-status') === 'hidden') {
            $(e.target).attr('data-status', 'shown')
            $('.show-more-tr-' + productId).removeClass('hidden')
            $(e.target).html('NASCONDI')
        } else {
            $(e.target).attr('data-status', 'hidden')
            $('.show-more-tr-' + productId).addClass('hidden')
            $(e.target).html('VEDI TUTTI')
        }
    })
});
